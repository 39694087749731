.gorwtholic-plan-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 8rem;
}

.gorwtholic-plan-title-container-parent {
    text-align: center;
}

.gorwtholic-plan-title-container {
    display: flex;
    flex-direction: column;
}

.gorwtholic-plan-title {
    font-weight: 500;
    font-family: "ProximaNova";
    color: #315038;
    font-size: 15px;
    text-transform: uppercase;
}


.gorwtholic-plan-title-description {
    font-weight: 500;
    font-family: "ProximaNova";
    color: #315038;
    font-size: 15px;
    text-transform: uppercase;
}

.gorwtholic-item {
    position: relative;
    width: auto !important;
}

.gorwtholic-item:hover {
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}


.growtholic-image {
    width: 270px;
    border-radius: 15px;
    object-fit: cover;
    filter: brightness(0.7);
    z-index: -1;
    height: 370px;
}

.gorwtholic-item-title {
    font-family: "ArgentCF-Thin";
    position: absolute;
    padding-top: 3%;
    padding-left: 2%;
    z-index: 9;

    width: inherit;
    gap: 0px;
    font-size: 45px;
    font-style: italic;
    font-weight: 100;
    line-height: 40px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FBF5F1;

    white-space: normal;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gorwtholic-item-description {
    color: #FBF5F1;
    position: absolute;
    padding-left: 2%;
    font-size: 18px;
    bottom: 5%;
    width: 250px;
    z-index: 9;

    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #FBF5F1;
}

.growth-plan-slider-container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media (320px <= width <= 1000px )  {

    .gorwtholic-plan-title-container-parent{
        margin-top: 12rem;
    }

    .gorwtholic-item:hover {
        cursor: pointer;
    }

}