.footer-container {
  align-self: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  height: 250px;
  background-color: #1d472d;
  width: 95%;
  border-radius: 20px;
  position: relative;
}

.logo-footer {
  position: absolute;
  top: 5%;
}

.powered-by-container {
  display: flex;
  gap: 0.5rem;
  position: absolute;
  right: 2%;
  bottom: 5%;
}

.powered-by {
  font-weight: 400;
  font-family: "ProximaNova";
  line-height: 18px;
  color: #7c9d85;
}

.politica-by-container {
  position: absolute;
  left: 2%;
  bottom: 5%;
}

.politica-by {
  font-weight: 400;
  font-family: "ProximaNova";
  line-height: 18px;
  color: #7c9d85;
}

@media (max-width: 399px) {
  .footer-container {
    margin-top: 11rem;
  }
}

@media (360px <= width <= 1000px )  {
  .footer-container {
    width: 90%;
    margin-top: 15rem;
  }

  .logo-footer {
    position: absolute;
    top: 0%;
    width: 90rem;
    left: 1%;
  }

  .powered-by-container {
    display: flex;
    gap: 1rem;
    position: absolute;
    align-items: center;
    right: 2%;
    bottom: 4%;
  }

  .powered-by {
    font-weight: 400;
    font-family: "ProximaNova";
    line-height: 18px;
    color: #7c9d85;
    font-size: 15px;
  }

  .politica-by-container {
    position: absolute;
    left: 2%;
    bottom: 4%;
  }

  .politica-by {
    font-weight: 400;
    font-family: "ProximaNova";
    line-height: 18px;
    color: #7c9d85;
    font-size: 15px;
  }

  .mob {
    width: 2rem;
  }
}
