.become-version-container {
  position: relative;
  overflow: hidden;
}

@keyframes breathing {
	0% {
	  transform: scale(0.9);
	  opacity: 0.8;
	}
	50% {
	  transform: scale(1);
	  opacity: 1;
	}
	100% {
	  transform: scale(0.9);
	  opacity: 0.8;
	}
  }

.random-picture-join{
  position: absolute;
  z-index: 1;
  animation: breathing 10s ease-out infinite;
}

.become-version-image {
  background-image: url("../../assets/dashboard_1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(-1);
  height: 100vh;
  max-width: 100%;
}

.become-version-logo {
  position: absolute;
  top: 0;
}

.become-version-title-container {
  position: absolute;
  top: 40%;
  left: 2%;
  width: 550px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  z-index: 9 !important;
}

.become-version-title {
  width: 616px;
  height: 248px;
  gap: 0px;
  z-index: 9 !important;
  font-family: Argent CF;
  font-size: 115.56px;
  font-style: italic;
  font-weight: 100;
  line-height: 84.36px;
  letter-spacing: -0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #F4F84E;
}

.become-version-description-container {
  width: 550px;
  z-index: 9 !important;
}

.become-version-description {
  color: #f4f84e;
  font-weight: 500;
  font-size: 23px;
  font-family: Proxima Nova;
  line-height: 25px;
  z-index: 9 !important;
  position: relative;
}

.start-now-parent-container {
  display: flex;
  justify-content: center;
}

.start-now-container {
  display: flex;
  position: absolute;
  bottom: 3%;
  background-color: #245938;
  padding: 1rem;
  border-radius: 3rem;
  width: 95%;
  gap: 5%;
  overflow: hidden;
  z-index: 9 !important;

}

.start-now-container span {
  font-size: 20px;
  color: #f4f84e;
  font-family: ProximaNova;
  animation: scrollText 10s linear infinite;
}

@keyframes scrollText {
  from {
    transform: translateX(50%);
  }

  to {
    transform: translateX(-50%);
  }
}

@media (320px <= width <= 800px )  {

  .become-version-image {
    background-size: cover;
    overflow: hidden;
    background-position: center;
    background-image: url("../../assets/responsive-images/become-version-responsive.png");
  }

  .random-picture-join{
    height: 100%;
  }

  .become-version-logo {
    width: 80rem;
  }

  .become-version-title-container {
    top: 50%;
    left: 50%;
    right: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    letter-spacing: -2px;
  }

  .become-version-title {
    line-height: 100px;
    font-family: Argent CF;
    font-size: 50px;
    width: 100%;
    text-align: center;
    font-style: italic;
    font-weight: 100;
    line-height: 50px;
    letter-spacing: -0.02em;
    align-self: center;
  }

  .become-version-description-container {
    margin-top: 1rem;
    width: 390px;
    text-align: center;
    align-self: center;
    width: 100%;
  }

  .become-version-description {
    font-size: 25px;
    text-align: center;
    font-family: ProximaNova;
    font-weight: 500;
    line-height: 30px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 1px;

  }

  .start-now-parent-container {
    display: flex;
    justify-content: center;
  }

  .start-now-container {
    width: 90%;
    overflow: hidden;
    padding-left: 1rem;
    justify-content: start;
    white-space: nowrap;
  }

  .start-now-container span {
    font-size: 20px;
  }
}