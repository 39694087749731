.progress-container {
    width: 100%;
    height: 5px;
    border-radius: 4px;
    border: 1px #1f5633 solid;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #1f5633 !important;
    transition: width 0.3s ease-in-out;
  }