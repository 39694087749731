.helmet_container {
}

.helmet_container .rs-breadcrumb {
	display: flex;
	align-items: center;
	gap: 10px;
}

.rs-breadcrumb {
	margin-bottom: 3px !important;
}

.rs-breadcrumb i, .rs-breadcrumb img {
	position: relative;
	top: 8px;
	padding: 0 7px !important;
	width: 20px !important
}

.rs-breadcrumb-separator {
    color: #fff;
    display: none;
}

