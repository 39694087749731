.vector {
	position: absolute;
	object-fit: cover;
	display: none;
}

.hero {
	width: 100%;
	height: 100vh;
	/*object-fit: fill; /* cover*/
	background-repeat: no-repeat;
	position: relative;
}
@keyframes breathing {
	0% {
	  transform: scale(0.9);
	  opacity: 0.8;
	}
	50% {
	  transform: scale(1);
	  opacity: 1;
	}
	100% {
	  transform: scale(0.9);
	  opacity: 0.8;
	}
  }

.carousel-indicators{
	z-index: 999 !important;
}
.random-pciture{
	position: absolute;
	z-index: 1;
	height: auto;
	width: auto;
	animation: breathing 10s ease-out infinite;
	top: -5;
}

img {
	object-fit: cover;
	background-repeat: no-repeat;
}

.hero-content {
	padding: 0 8.2vw;
	position: absolute;
	bottom: 10.3%;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;
	z-index: 9 !important;
}

.line-height_val {
	line-height: .5 !important;
}

.width_60 {
	width: 75% !important;
}

.CTA_button_container {
	display: flex;
}

.home_sub_upcoming_event {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 1.5rem;
    justify-items: center;
}

.text-view {
	display: flex;
	width: 283px;
	min-height: 380px;
	padding: var(--spacing-60);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-40);
	flex-shrink: 0;
	border-radius: var(--spacing-40);	
}

.text-view-mobile{
	display: flex;
	width: 283px;
	height:350px;
	padding: var(--spacing-60);
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-40);
	flex-shrink: 0;
	border-radius: var(--spacing-40);	
}

.feature_img {
	width: 100%;
	height: 380px;
	border-radius: var(--spacing-40);
	object-fit: cover;
}

.home_card_title {
	font-family: Helvetica;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
   line-height: 120%; /* 28.8px */
}

.home_card_sub_text {
	font-family: Helvetica;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 26px */
}

.feature_container {
	display: flex;
	width: 100%;
	align-items: flex-start;
	align-content: flex-start;
	gap: 22px;
	flex-wrap: wrap;
}

.hero_upcoming_event_details {
	position: absolute;
	left: 24px;
	top: 24px;
	right: 24px;
	text-align: left;
}

.yellow_container {
	width: 100%;
	color: var(--color-secondary-70);
	display: flex;
	padding: var(--spacing-80);
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-60);
	border-radius: var(--spacing-40);
	background: var(--color-secondary-100);
}

.yellow_text_container {
	display: flex;
	width: 76%;
	margin: 0 12%;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-20);
}

.yellow_bottom_container {
	display: flex;
	align-items: flex-start;
	gap: 64px;
	justify-content: space-between;
}

.yellow_bottom_container .yellow_img {
	width: auto;
	max-height: 280px;
	border-radius: var(--spacing-40);
}

.yellow_container .right_view {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-40);
	width: 70%;
}

.quiz_bg_1 {
	min-width: 70%;
	background-image: url('../../assets/Vector 269.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;	
	padding: 48px 200px 48px 48px;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: var(--spacing-60);
}

.quiz_bg_2 {
	min-width: 50%;
	background-image: url('../../assets/Rectangle 1893.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: auto;
	position: relative;
	left: -20%;
	min-height: 550px;
	position: relative;
	padding: 100px;
}

.start-here-sub-text {
	width: 40%;
}

.start-here h1 {
	width: 60%;
}

.start-here-event-card-container {
	display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 6rem;
    justify-content: space-between;
}

.start-here-catalyst-card {
	background-color: #F8F9FA;
	border-radius: 5px;
}

.start-here-rounded-image {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}

.start-here-mission-container {
	display: flex;
	padding: var(--spacing-80);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 36px;
}

.start-here-catalyst-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 6rem;
    justify-items: center;
}

.start-here-catalyst-img {
	/*width: 70%;
	margin: 0 15%;
	border-radius: 50%;*/
	width: 100%;
}

.head_icon {
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.h2_heading {
	font-size: 32px;
	font-weight: normal;
	line-height: 1.2;
}

.h3_heading {
	font-size: 24px;
	font-weight: normal;
}

hr {
	border-top: 2px solid #000 !important;
}

@media screen and (min-width: 640px) and (max-width: 1486px) {
	.home_sub_upcoming_event {
		display: grid;
	    grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
	.hero {
		width: 100vw;
		height: auto;
		background-size: contain;
	}

	.start-here-sub-text {
		width: 50%;
	}
}

@media only screen and (min-device-width: 820px) and (max-device-width: 1023px) {
	.hero {
		width: 100vw;
		height: auto;
		background-size: contain;
	}

	.start-here-sub-text {
		width: 75%;
	}
}

@media only screen and (min-device-width: 769px) and (max-device-width: 819px) {
	.hero {
		width: 100vw;
		height: auto;
		background-size: contain;
	}

	.home_sub_upcoming_event {
		display: grid;
	    grid-template-columns: repeat(2, 1fr);
	}

	.start-here-sub-text {
		width: 50%;
	}

	
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
	.hero {
		width: 100vw;
		height: auto;
		background-size: contain;
	}

	.hero-content {
		gap: 5px;
	}

	.start-here-sub-text {
		width: 77%;
	}

	.home_sub_upcoming_event {
	    grid-template-columns: repeat(2, 1fr);
	}

	.yellow_text_container {
		display: flex;
		width: 100%;
		margin: 0 0;
	}

	.yellow_bottom_container {
		flex-direction: column;
	}

	.yellow_container .right_view {
		width: 100%;
	}

	.yellow_bottom_container .yellow_img {
		width: 100%;
		max-height: none;
	}


}


@media (375px <= width <= 700px )  {
	.random-pciture{
		position: absolute;
		height: 300px;
		z-index: 4;
		animation: breathing 3s ease-out infinite;
		top: 40%;
		left: -10px;
	}
}

@media (max-width: 992px) {


	.quiz_bg_1 {
	    width: 100%;
	    background-color: var(--color-accent-100);
	    padding: 48px;
	    border-radius: var(--spacing-40);
	}

	.quiz_bg_2 {
	    display: none;
	}

	.yellow_bottom_container {
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.start-here-CTA {
		width: 100%;
		display: flex;
		margin-top: 2rem;
		padding: 1rem;
		justify-content: center
	}

	.start-here-CTA button {
		width: 50%;
	}

	.yellow_bottom_container .right_view {
		width: 100%;
	}
}

@media screen and (max-width: 640px) {

	.hero {
		width: 100vw;
		height: 70vh;
		background-size: cover;
	}

	.start-here-sub-text {
		width: 100%;
	}

	.start-here {
		font-size: 18px;
	}


	.start-here-sub-text {
		width: 100%;
	}

	.start-here h1 {
		font-size: 48px;
		width: 100%;
	}

	.head_icon {
		display: none;
	}

	.start-here-CTA {
		width: 100%;
		display: flex;
		margin-top: 2rem;
		padding: 1rem;
		justify-content: center
	}

	.start-here-CTA button {
		width: 100%;
	}

	.start-here-event-card-container {
		display: grid;
	    grid-template-columns: repeat(3, 1fr);
	    align-items: center;
	    gap: 1rem;
	    justify-content: space-between;
	}

	.start-here-catalyst-card-container {
	    display: grid;
	    grid-template-columns: repeat(1, 1fr);
	    align-items: center;
	    gap: 6rem;
	    justify-items: center;
	}

	.h2_heading {
		font-size: 24px;
		font-weight: normal;
	}

	.h3_heading {
		font-size: 14px;
		font-weight: normal;
	}

	.hero_upcoming_event {
		padding: 3rem 4.2vw;
	}

	.quiz_bg_1 {
		padding: 24px;
	}

	.yellow_container {
		padding: var(--spacing-40);
		gap: var(--spacing-20);
		border-radius: var(--spacing-40);
	}

	.yellow_container .right_view {
		padding-left: 0rem;
		padding: 2rem 0 0 0;
	}

	.yellow_text_container {
		display: flex;
		width: 100%;
		margin: 0 0;
	}

	.home_sub_upcoming_event {
	    grid-template-columns: repeat(1, 1fr);
	}

	.CTA_button_container {
		display: flex;
	}

	.CTA_button_container button {
		margin: .5rem 0;
	}

	.yellow_bottom_container {
		display: block;
		width: 100%;
	}

	.yellow_container .right_view {
		display: block;
		width: 100%;
	}

	.yellow_bottom_container .yellow_img {
		max-height: 100%;
		width: 100%;
	}

}

