.promo-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promo-code-child-container {
    background-color: white !important;
    width: 40%;
    border-radius: 10px;
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.promo-code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.promo-code-header>h2 {
    color: #245938;
    font-family: Argent CF;
    font-weight: 400;
    font-size: 45px;
    line-height: 57.6px;
}

.promo-code-header>button {
    all: unset;
    color: #245938;
    background-color: #DAD1FC;
    font-size: 16px;
    font-family: Lato;
    font-weight: 600;
    padding: 0.7rem;
    width: 140px;
    text-align: center;
    border-radius: 10px;
    border: 2px #245938 solid;
}

.promo-code-active-expired {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 1rem;
}

.promo-code-active-expired>label {
    color: #245938;
}

.coupons-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
}

.coupon-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coupon-item {
    display: flex;
    flex-direction: column;
}

.coupon-item>label {
    color: #245938;
}

.coupon-item :nth-child(1) {
    font-family: Lato;
    font-size: 12px;
}

.coupon-item :nth-child(2) {
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
}

.coupon-item :nth-child(3) {
    font-family: Lato;
    font-size: 15px;
}
.coupon-view-container{
    display: flex;
    justify-content: center;
}
.coupon-view-all{
    all: unset;
    width:90%;
    font-weight: 500;
    text-align: center;
    border: 2px #245938 solid;
    padding: 0.5rem;
    border-radius: 7px;
    margin-bottom: 1rem;
    font-family: Lato;
    font-size: 20px;
}

.modal-container{
    min-width:35% !important;
}

@media (375px <= width <= 1450px )  {
    .promo-code-child-container {
            background-color: white !important;
            width: 100%;
            border-radius: 10px;
            padding-top: 2rem;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    
        .promo-code-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
        }
    
        .promo-code-header>h2 {
            color: #245938;
            font-family: Argent CF;
            font-weight: 400;
            font-size: 25px;
            line-height: 57.6px;
        }
    
    
        .promo-code-header>button {
            all: unset;
            color: #245938;
            background-color: #DAD1FC;
            font-size: 12px;
            font-family: Lato;
            font-weight: 600;
            padding: 0.7rem;
            width: 240px;
            text-align: center;
            border-radius: 10px;
            border: 2px #245938 solid;
        }

}