.feeling-container{
    display: flex;
    overflow: hidden;
    position: relative;
}

.tablet_view{
    display: none !important;
}

.feeling-picture{
    position: absolute;
    left: 41rem;
    height: 650px;
}

.feeling-text-picture{
    margin-left: 2rem;
    height: 650px;
}
.feeling-details-inner-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    width: 45%;
    left: 5rem;
    top: 1rem;
}
.feeling-details-inner-container p , span{
    color: #245938;
}

.feeling-title{
    font-size: 25px;
    text-transform: uppercase;
}

.feeling-title-details{
    font-size: 45px;
}

.feeling-title-details-desire{
    font-size: 27px;
    line-height: 120%;
}

.feeling-title-details-journey{
    font-family: Lato;
    font-size: 20px;
    width: 70%;
    font-weight: 700;
}

.mobile_feel_container{
    display: flex !important;
    gap: 1.5rem;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
    background-color: #DAD1FC;
    padding: 1rem;
    border-radius: 15px;
}

.mobile_feel_container , span, p{
    color: #245938;
}

.feeling_title_mobile{
    text-transform: uppercase;
    font-family: Lato;
    font-size: 15px;
}

.feeling_title_details_mobile{
text-align: center;
font-size: 38px;
font-family: ArgentCF;
line-height: 100%;
}

.feeling-title-details-desire_mobile{
    font-family: ArgentCF;
    line-height: 120%;
    font-size: 22px;
    text-align: center;
}

.secondary_btn_mobile{
   padding: 0px !important;
   margin: 0  0 0 0 !important;
}

.feeling_title_details_journey_mobile{
    text-align: center;
    font-family: Lato;
    font-size: 16px;
}

.feeling-text-container{
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

.feeling-text-container, .feeling_title_details_mobile{
    text-align: start !important;
}
@media (width < 768px) {

    .tablet_view {
        display: none !important;
    }
}


@media (768px <=width <=1024px) {

    .feeling-container {
        display: none !important;
    }
    .feeling-text-container{
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        width: 100%;
    }
}


@media (1440px <=width) {
    .tablet_view {
        display: none !important;
    }

    .feeling-container {
        display: flex;
        overflow: hidden;
        position: relative;
    }
}