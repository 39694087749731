.arrow-container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    z-index: 999;
    top: 250px;
}

.arrow {
    width: 30px;
    height: 30px;
  }

  .arrow::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border: solid black;
    border-width: 4px 4px 0 0;
    display: inline-block;
  }

  .left::before {
    transform: rotate(-135deg);
    left: 10px;
  }

  .right::before {
    transform: rotate(45deg);
    right: 10px;
  }