* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    /*color: #212529;*/
    word-wrap: break-word; 
   
    font-family: "ArgentCF-Regular";
}

.row>* {
    /*padding-left: 0;*/
    /*padding-right: 0;*/
}

.opacity_0 {
    opacity: 0;
    width: 0;
    height: 0;
}

hr {
    border: 1px solid #ccc !important;
    width: 100% !important;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-bottom: 0 !important;
}

.rs-picker-menu {
    z-index : 2000 !important;
}

.MuiDialogContent-root {
    padding: 0 24px !important;
}

.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

button:disabled {
    opacity: .5;
}

.isDisabled {
    opacity: .3;
}

body {
    font-size: 16px;
}

u {
    text-decoration: underline !important;
}

@font-face {
    font-family: "ArgentCF-Bold";
    src: url("./fonts/ArgentCF-Bold.otf") format("opentype");
}

@font-face {
    font-family: "ArgentCF-Regular";
    src: url("./fonts/ArgentCF-Regular.otf") format("opentype");
}

@font-face {
    font-family: "ArgentCF-Thin";
    src: url("./fonts/ArgentCF-Thin.otf") format("opentype");
}

@font-face {
    font-family: "Lato";
    src: local("Lato"),
    url("./fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ProximaNova";
    src: url("./fonts/Proxima_Nova_Font.otf");
  }


textarea {
    resize: none !important;
}

.opacity {
    opacity: .7;
}

/*
.row >* {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
*/

:root {

    --spacing-20: 8px;
    --spacing-30: 12px;
    --spacing-40: 16px;
    --spacing-50: 20px;
    --spacing-60: 24px;
    --spacing-70: 48px;
    --spacing-80: 48px;
    --spacing-90: 64px;
    --spacing-110: 80px;
    
    --color-primary-100: #1F4D30;
    --color-primary-50: #245938;
    --color-primary-20: #4E705A;
    --color-primary-10: #739E83;

    --color-secondary-100: #B67B22;
    --color-secondary-90: #F2D7C5;
    --color-secondary-80: #F7E7D9;

    --color-secondary-70: #FDF6EE;

    --color-accent-100: #DBD1FF;
    --color-accent-90: #F4F84E;
    --color-accent-200: #F5FA28;
    
    --color-singals-Error: #FD4300;
    --color-singals-Warning: #FF9A00;
    --color-singals-Success: #01D243;

    --color-neutral-40: #5E666D;
    --color-neutral-50: #6C757D;
    --color-neutral-90: #F8F9FA;

    --color-neutral-100 : #FFFFFF;
    --color-primary-brand-a : #193F27;

    --bg-dark-green: #1F4D30;
    --bg-lilac: #DBD1FF;
    --bg-pale-pink: #FDF6EE;
    --bg-light-salmmon: #F7E7D9;
    --bg-salmmon: #F2D7C5;
    --bg-ocre: #B67B22;
    --lemon-green: #D4DCCE;

    --dark-green: #1F4D30;
    --lilac: #DBD1FF;
    --highlight: #F4F84E;
    --pastel-green: #739E83;
    --white: #FFFFFF;
    --pale-pink: #FDF6EE;
    --light-salmmon: #F7E7D9;
    --salmmon: #F2D7C5;
    --ocre: #B67B22;
    --deep-green: #245938;

    --bg-color: #212529;
    --rs-body: #FDF6EE !important;
    --color-primary-brand-b: #016F2A;
    --second-bg-color: #323946;
    --text-color: #000; /* 000*/
    --rs-text-primary: #000 !important;
    --main-color: #000;
    --cardBackgroundColor: 176,207,187,0.3;
    --color_31: 247,250,192;
    
    --vertical-padding: 30;
    --horizontal-padding: 30;
    --plan-top-bg-color: 255,246,237,0.26;
    --plan-cards-spacing: 32;
    --card-border-color: 78,112,90;
    --card-border-corner-radius: 0;
    --card-border-width: 2;
    --card-border-color: 78,112,90;

    --rs-input-focus-border: #739E83;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(---deep-green); 
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--dark-green) ;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: #E3DDD6;
}

body {
    background-color: var(--rs-body) !important;
}

main {
    background-color: var(--rs-body);
    overflow: hidden;
}

.rs-body {
    background-color: var(--rs-body);
}

.color-accent-90 {
    color: var(--color-accent-90);
}

.color-primary-20 {
   color: var(--color-primary-20); 
}

.color-primary-10 {
    color: var(--color-primary-10);
}

.color-primary-100 {
    color: var(--color-primary-100);
}

.color-primary-50 {
    color: var(--color-primary-50);
}

.color-neutral-00 {
    color: var(--color-neutral-00);
}

.color-neutral-70 {
    color: var(--color-neutral-70);
}

.color-neutral-40 {
    color: var(--color-neutral-40);
}

.color-neutral-90 {
    color: var(--color-neutral-90);
}

.color-secondary-70 {
    color: var(--color-secondary-70);
}

.color-secondary-100 {
    color: var(--color-secondary-100);
}

.color-primary-brand-a {
    color: var(--color-primary-brand-a);
}

.primary_100_bg {
    background: var(--color-primary-100);
}

.primary_10_bg {
    background: var(--color-primary-10);
}

.color-primary-50_bg {
    background-color: var(--color-primary-50);
}

.color-secondary-90_bg {
    background-color: var(--color-secondary-90);
}

.color-secondary-80_bg {
    background-color: var(--color-secondary-80);
}

.color-peach_bg {
    background-color:  var(--salmmon) !important;
}

.color-selmon_bg {
    background-color: var(--bg-light-salmmon);
}

.color-neutral-50 {
    color: var(--color-neutral-50);
}

.color-neutral-90_bg {
    background-color: var(--color-neutral-90);
}

.accent_90_bg {
    background: var(--color-accent-90);
}

.accent_100_bg {
    background: var(--color-accent-100);
}

.secondary_100_bg {
    background: var(--color-secondary-100);
}

.secondary_80_bg {
    background: var(--color-secondary-80);
}

.secondary_90_bg {
    background: var(--color-secondary-90);
}

.catalyst_profile_img {
    width: 100%;
    height: 250px;
    margin: 0 auto;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
}

.catalyst_overview_profile_img {
    width: auto;
    max-height: 300px;
    margin: 0 auto;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: center;
}

.shadow_text {
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.white_text {
    color: var(--color-neutral-100);
}

.gray_text {
    color: var(--color-neutral-40);
}

.green {
    color: var(--color-primary-brand-b) !important;
}

.green_bg {
    background-color: var(--deep-green) !important;
}

.yellow_btn {
    border-radius: var(--spacing-20);
    background: var(--color-accent-90);
    color: var(--color-primary-50);

    display: flex;
    min-width: 200px;
    min-height: 48px;
    padding: var(--spacing-50);
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.grid_layout {
    display: flex;
    min-width: 200px;
    min-height: 48px;
    padding: var(--spacing-50);
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.primary_btn, .primary_btn:active { /*yellow*/
    display: inline-flex;
    min-width: 200px;
    min-height: var(--spacing-80);
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: var(--spacing-20);
    background: var(--color-accent-90);
    color: var(--color-primary-50);
    text-align: center;
}

.primary_btn:hover, .accent_90_bg:hover {
    background: var(--color-accent-200);
    color: var(--color-primary-50);
} 

.secondary_btn, .secondary_btn:active { /* green */
    display: inline-flex;
    min-width: 200px;
    min-height: var(--spacing-80);
    /*padding: var(--spacing-50);*/
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 20px;

    border-radius: var(--spacing-20);
    background: var(--color-primary-50);
    color: var(--color-secondary-70);
    text-align: center;
}

.secondary_btn:hover {
    background: var(--color-primary-100);
    color: var(--color-secondary-70);
}

.tertiary_btn {
    display: inline-flex;
    min-width: 200px;
    min-height: var(--spacing-80);
    /*padding: var(--spacing-50);*/
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 var(--spacing-20);
    border-radius: var(--spacing-20);
    border: 1px solid var(--color-primary-50);
    background: rgba(255, 255, 255, 0.00);
    cursor: pointer;
}

.tertiary_btn:hover {
    background-color: var(--color-primary-50);
    color: var(--color-neutral-100);
}

.login_btn {
    display: flex;
    min-width: 200px;
    min-height: 48px;
    padding: var(--spacing-20);
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: var(--spacing-20);
   
}

.tertiary_text {
    color: var(--color-primary-50);
    text-align: center;
    font-family: Helvetica;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    text-transform: uppercase;
}

.book_btn {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: var(--deep-green);
   
    padding: 1rem 1rem;
    width: 100%;
    color: var(--highlight);
    text-align: center;
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 16.2px */
    text-transform: uppercase;
}

.common_padding {
    padding: 2rem 8.2vw;
}

.main-content-padding {
    padding: var(--spacing-80) 8.2vw var(--spacing-110) 8.2vw;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    gap: var(--spacing-110);
}

.main-no-content-padding {
    padding: 40px 8.2vw;
}

.fc-day-number {
  font-size: 1.5em;
  color: #5c005c;
}

.fc-day-header {
  font-size: 1.5em;
  color: #00b294;
}

.ant-picker-cell-in-view.ant-picker-cell-selected,  .ant-picker-ok button {
    background: var(--dark-green) !important;
}

.ant-picker-today-btn, .ant-picker-now-btn  {
    color : var(--dark-green) !important;
}

.ant-picker-cell-inner::before {
    border : 1px solid var(--dark-green) !important;
}

.desktop_view {
    display: block;
}

.mobile_view {
    display: none;
}

.sub_text {
    font-family: 'Lato';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 26px */
}

.font-400 {
    font-weight: 400 !important;
}

.width-75 {
    width: 75%;
}

.width-60 {
    width: 60%;
}

.h1, .heading-1 {
    /*Headline 1*/
    font-family: "ArgentCF-Thin";
    font-size: 80px;
    font-style: normal;
    font-weight: 100;
    line-height: 120%; /* 96px */
}

.h2, .heading-2 {
    /* Headline 2 */
    font-family: "ArgentCF-Thin";
    font-size: 64px;
    font-style: normal;
    font-weight: 100;
    line-height: 120%; /* 76.8px */
}

.h3, .heading-3 {
    /* Headline 3 */
    font-family: "ArgentCF-Regular";
    font-size: 60px;
    font-style: normal;
    font-weight: 100;
    line-height: 120%; /* 72px */
}

.h4, .heading-4 {
    /* Headline 4 */
    font-family: "ArgentCF-Regular";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 57.6px */
}

.h5, .heading-5 {
    /* Headline 5 */
    font-family: "ArgentCF-Regular";
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
}

.h6, .heading-6 {
   /* Headline 6 */
    font-family: "ArgentCF-Regular";
    font-size: 24px;
    font-style: normal;
    font-weight: 100;
    line-height: 120%; /* 28.8px */
}

.h7, .heading-7 {
    /* Headline 7 */
    font-family: "ArgentCF-Regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 100;
    line-height: 120%; /* 24px */
}

.subtitle-R {
    /* subtitle R */
    font-family: "ArgentCF-Regular";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 28.8px */
}

.subtitle-B {
    /* subtitle B */
    font-family: "Lato";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
}

.caption-R {
    /* Caption R */
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
}

.caption-B {
    /* Caption B */
    font-family: "Lato";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
}

.body-R {
    /* body R */
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
}

.body-CAP {
    /* body CAP */
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 24px */
}

.body-CAP-small {
    font-family: "Lato";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 24px */
}

.body-B {
    /* body B */
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
}

.caption-2 {
    /* Caption 2 */
    font-family: "ArgentCF-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}

.caption-3 {
    /* Caption 3 */
    font-family: "Lato";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}

.LINK { 
    font-family: "Lato";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
}

.primary_btn.LINK:hover {
    color: var(--color-primary-50);
} 

.LINK:hover {
    /*color: var(--color-accent-90);*/
    cursor: pointer;
    /*opacity: .7;*/
}

.menu {
    /* MENU */
    font-family: "ArgentCF-Regular";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%; /* 27px */
    text-transform: uppercase;
}

.menu-item {
    /* Dropdown Menu */
    font-family: "ArgentCF-Regular";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 14.85px */
}

form label {
    margin-bottom: 0.5rem;
    font-family: "ArgentCF-Thin";
    font-size: 16px;
    font-style: normal;
    font-weight: 450;
    line-height: 100%;
}

.responsive_flex {
    display: flex;
    align-items: center;
    /*flex-direction: column;*/
    /*justify-content: space-between;*/
}

.width-50 {
    width: 50%;
}

.mobile_responsive_btn {
    width: auto;
}

@media screen and (max-width: 1366px) {
    .h1, .heading-1 {
        /*Headline 1*/
        font-size: 70px;
    }
}

@media screen and (max-width: 1024px) {
    .primary_btn, .seconadry_btn, .tertiary_btn {
        min-width: auto;   
    }

    .width-50 {
        width: 100%;
    }

    .h1, .heading-1 {
        /*Headline 1*/
        font-size: 54px;
    }

    .h2, .heading-2 {
        /* Headline 2 */
        font-size: 48px;
    }

    .h3, .heading-3 {
        /* Headline 3 */
        font-size: 42px;
    }

    .h4, .heading-4 {
        /* Headline 4 */
        font-size: 36px;
    }

    .h5, .heading-5 {
        /* Headline 5 */
        font-size: 28px;
    }

    .h6, .heading-6 {
       /* Headline 6 */
        font-size: 18px;
    }

    .h7, .heading-7 {
        /* Headline 7 */
        font-size: 14px;
    }

    .subtitle-R {
        /* subtitle R */
        font-size: 18px;
    }

    .subtitle-B {
        font-size: 18px;
    }

    .caption-R {
        /* Caption R */
        font-size: 12px;
    }

    .caption-B {
        /* Caption B */
        font-size: 14px;
    }

    .body-R {
        /* body R */
        font-size: 18px;
    }

    .body-CAP {
        /* body CAP */
        font-size: 18px;
    }

    .body-B {
        /* body B */
        font-size: 18px;
    }

    .caption-2 {
        /* Caption 2 */
        font-size: 12px;
    }

    .caption-3 {
        /* Caption 3 */
        font-size: 10px;
    }

    .LINK { 
        font-size: 16px;
    }

    .menu {
        /* MENU */
        font-size: 18px;
    }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {

}

@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
    .navbar1.active .mobile_view {
        display: block !important;
    }

    .width-50 {
        width: 100%;
    }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
    

    .navbar1.active .mobile_view {
        display: block !important;
    }

    .width-50 {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .width-60 {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {

    body {
        font-size: 12px;
    }

    .LINK { 
        font-size: 16px;
    }

    .main-content-padding {
        padding: var(--spacing-80) 8.2vw var(--spacing-80) 8.2vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-80);
    }

    .primary_btn { /*yellow*/
        display: inline-flex;
        min-width: 150px;
        min-height: var(--spacing-80);
        /*padding: var(--spacing-50);*/
        /*padding: 10px 24px;*/

        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: var(--spacing-20);
        background: var(--color-accent-90);
        color: var(--color-primary-50);
        text-align: center;
    }

    .secondary_btn { /* green */
        display: inline-flex;
        min-width: 150px;
        min-height: var(--spacing-80);
        /*padding: var(--spacing-50);*/
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: var(--spacing-20);
        background: var(--color-primary-50);
        color: var(--color-secondary-70);
        text-align: center;
    }

    .tertiary_btn {
        display: inline-flex;
        min-width: 120px;
        min-height: var(--spacing-80);
        padding: 0 var(--spacing-20);
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 10px;
        border-radius: var(--spacing-20);
        border: 1px solid var(--color-primary-50);
        background: rgba(255, 255, 255, 0.00);
    }

    .padding {
        padding: 2rem; /* Amit padding */
    }

    .width-75 {
        width: 100%;
    }

    .width-60 {
        width: 100%;
    }

    .desktop_view {
        display: none;
    }

    .mobile_view {
        display: block;
    }

    .book_btn {
        width: 100%;
        margin: 0 0;
    }

    .h1, .heading-1 {
        /*Headline 1*/
      
        font-size: 36px;
        font-style: normal;
        font-weight: 100;
        line-height: 120%; /* 96px */
    }

    .h2, .heading-2 {
        /* Headline 2 */
       
        font-size: 32px;
        font-style: normal;
        font-weight: 100;
        line-height: 120%; /* 76.8px */
    }

    .h3, .heading-3 {
        /* Headline 3 */
      
        font-size: 24px;
        font-style: normal;
        font-weight: 100;
        line-height: 120%; /* 72px */
    }

    .h4, .heading-4 {
        /* Headline 4 */
        
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 57.6px */
    }

    .h5, .heading-5 {
        /* Headline 5 */

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 38.4px */
    }

    .h6, .heading-6 {
       /* Headline 6 */
       
        font-size: 16px;
        font-style: normal;
        font-weight: 100;
        line-height: 120%; /* 28.8px */
    }


    .h7, .heading-7 {
        /* Headline 7 */
       
        font-size: 15px;
        font-style: normal;
        font-weight: 100;
        line-height: 120%; /* 24px */
    }

    .subtitle-R {
        /* subtitle R */
       
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 28.8px */
    }

    .subtitle-B {
        /* subtitle B */
        
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 28.8px */
    }

    .caption-R {
        /* Caption R */
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
    }

    .caption-B {
        /* Caption B */
        
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 19.2px */
    }


    .body-R {
        /* body R */
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
    }

    .body-CAP {
        /* body CAP */
        
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 24px */
    }

    .body-B {
        /* body CAP */
       
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */
    }

    .caption-2 {
        /* Caption 2 */
        
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
    }

    .caption-3 {
        /* Caption 3 */
       
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
    }

    .LINK { 
       
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
    }

    .sub_text {
        color: var(--color-neutral-100);
        padding-right: 0rem;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 26px */
    }

    .responsive_flex {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /*Amit Patch*/
        justify-content: center;
    }

    .width-50 {
        width: 100%;
    }

    .mobile_responsive_btn {
        width: 100%;
    }
}