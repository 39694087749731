.growth-journey-container {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.growth-journey-items-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
}

.growth-journey-title {
  width: 100%;
  height: auto;
  font-family: "ArgentCF";
  font-size: 62.92px;
  font-style: italic;
  font-weight: 100;
  line-height: 61px;
  letter-spacing: -0.02em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #315038;
}
.button-container {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  position: relative;
}

.button-period {
  all: unset;
  text-align: center;
  border: 2px solid #315038;
  width: 15%;
  cursor: pointer;
  padding: 1%;
  border-radius: 15px;
  margin-left: -10px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.button-period-select {
  background-color: #315038;
  color: white;
}

.button-yearly {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-period:hover {
  background-color: #315038;
  color: white;
}

.yearly-off {
  position: absolute;
  right: 25%;
  top: 100%;
}
.yearly-off-on-top-container {
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}

.save-more {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-size: 20px;
  position: absolute;
  color: #315038;
  line-height: 16.5px;
  right: 18%;
  top: 25%;
  width: 12%;
  text-align: center;
}
.save-more-description {
  justify-content: center;
  position: relative;
  width: 885px;
  height: 26px;
  font-family: Lato;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: center;
  color: var(--Color-Primary-50, #245938);
  padding-top: 0.5%;
}

@media (320px <= width <= 1000px )  {
  .growth-journey-title {
    font-family: "ArgentCF";
    font-size: 35px;
    font-style: italic;
    font-weight: 100;
    line-height: 61px;
    text-align: center;
    color: #315038;
    width: 100%;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    position: relative;
    margin-bottom: 7rem;
  }
  .button-period {
    all: unset;
    text-align: center;
    border: 2px solid #315038;
    width: 30%;
    cursor: pointer;
    padding: 2%;
    border-radius: 15px;
    font-size: 16px;
    margin-left: -10px;
    font-family: Lato;
    font-weight: 700;
  }

  .button-period-select {
    background-color: #315038;
    color: white;
  }

  .button-period:hover {
    background-color: #315038;
    color: white;
  }

  .yearly-off {
    position: absolute;
    right: 25%;
    top: 150%;
    transform: rotate(320deg);
    width: 70px;
  }

  .save-more {
    font-family: Lato;
    font-weight: 700;
    font-size: 25px;
    position: absolute;
    color: #315038;
    line-height: 20px;
    left: 35%;
    top: 180%;
    width: 25%;
    text-align: center;
  }

  .growth-journey-items-container {
    display: block !important;
  }
}
