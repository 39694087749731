.growth-item-container {
  margin-top: 3rem;
  height: auto;
  width: 500px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.1s;
  margin-bottom: 10rem;
  position: relative;
}

.limited-time-offer {
  position: absolute;
  background-color: #dbd1ff;
  padding: 15px;
  margin-top: -25px;
  width: auto;
  border-radius: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: #245938;
  font-family: "Lato", sans-serif;
  font-size: 20px;
}

.selected-growth-item {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  border: 3px solid #dbd1ff;
}

.growth-item-container:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  border: 3px solid #dbd1ff;
}

.growth-item-title {
  font-size: 48px;
  margin-top: 2rem;
  font-family: "ArgentCF";
  color: #245938;
}

.additional-features-description-container {
  display: flex;
  flex-direction: column;
}

.additional-features-description-container ul {
  list-style: none;
}

.additional-features-description-container li {
  color: #245938;
  font-family: Lato;
}
.additional-features-description-container li::before {
  text-indent: -0.7em;
  content: "• ";
  color: #245938;
}

.growth-item-title-description {
  color: #245938;
  font-family: Lato;
}

.growth-image-item {
  margin-top: 1.5rem;
}

.quarterly {
  border: 3px solid #dbd1ff;
}

.yearly {
  width: 592px;
  border: 3px solid #739e83;
}

.growth-journey-previous-price {
  font-size: 48px;
  color: #245938;
  font-family: "ArgentCF";
  text-decoration: line-through;
  opacity: 0.3;
}
.growth-journey-previous-price-monthly {
  font-size: 20px;
  color: #245938;
  opacity: 0.3;
}

.growth-journey-price-container {
  margin-top: 1rem;
}

.growth-journey-price-container-empty {
  margin-top: 5.5rem;
}

.growth-journey-price {
  font-size: 48px;
  color: #245938;
  font-family: "ArgentCF";
}
.growth-journey-price-monthly {
  font-size: 20px;
  color: #245938;
}

.growth-journey-discount-container {
  font-size: 16px;
  color: #245938;
  font-family: Lato;
  height: 26px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0%;
  text-align: center;
}

.separator {
  margin-top: 20px;
  background-color: #739e83;
  width: 80%;
  height: 0.7px;
}

.separator-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.feature-container {
  width: 90%;
  align-items: center;
  display: flex;
  gap: 5px;
}

.feature-text {
  font-size: 20px;
  color: #245938;
  margin-top: 20px;
  font-family: Lato;
}

.feature-title-container {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
}

.feature-text-title {
  font-size: 20px;
  color: #245938;
  font-family: Lato;
}
.addition-text-container {
  width: 80%;
}
.addition-text {
  color: #245938;
  font-size: 20px;
  align-self: self-start;
  font-family: Lato;
}
.additional-feature-container {
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
}

.button-select {
  all: unset;
  color: #245938;
  border: 1px solid #245938;
  padding: 3%;
  border-radius: 10px;
  width: 80%;
  text-align: center;
  margin-top: auto;
  cursor: pointer;
  font-weight: 700;
  justify-self: flex-end;
  margin-bottom: 5rem;
  font-family: "Lato", sans-serif;
}

.button-select:hover {
  background-color: #245938;
  color: white;
}

.check-mark-image {
  width: 20px;
  height: 20px;
}
@media (320px <= width <= 1000px )  {
  .growth-item-container {
    width: 100% !important;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.1s;
    position: relative;
    margin-top: 0rem;
    margin-bottom: -10rem;
    position: relative;
  }
  .growth-item-title {
    font-size: 30px;
    margin-top: 2rem;
    text-align: center;
    font-family: "ArgentCF";
    color: #245938;
  }

  .growth-item-title-description {
    color: #245938;
    font-size: 16px;
    font-family: "Lato";
    font-weight: 400;
    font-family: Lato;
    padding: 0 1rem;
    text-align: center;
  }

  .growth-image-item {
    margin-top: 1.5rem;
    width: 10em;
  }

  .growth-journey-price-container {
    margin-top: 2rem;
  }
  .growth-journey-previous-price {
    font-size: 38px;
    color: #245938;
    font-family: "ArgentCF";
    text-decoration: line-through;
    opacity: 0.3;
  }
  .growth-journey-price-container-empty {
    margin-top: 0px;
  }

  .growth-journey-price {
    font-size: 34px;
    color: #245938;
    font-family: "ArgentCF";
  }
  .growth-journey-price-monthly {
    font-size: 25px;
    color: #245938;
  }

  .separator {
    margin-top: 20px;
    background-color: #739e83;
    width: 80%;
    height: 0.7px;
  }

  .separator-container {
    width: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .feature-container {
    width: 80%;
    display: flex;
    gap: 5px;
  }

  .feature-text {
    font-size: 20px;
    color: #245938;
    margin-top: 20px;
  }

  .feature-title-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .feature-text-title {
    font-size: 20px;
    color: #245938;
  }
  .addition-text-container {
    width: 80%;
    margin-top: 1rem;
  }
  .addition-text {
    color: #245938;
    font-size: 25px;
    align-self: self-start;
  }
  .additional-feature-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .button-select {
    all: unset;
    color: #245938;
    border: 2px solid #245938;
    padding: 3%;
    border-radius: 10px;
    width: 60%;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    font-weight: 700;
    justify-self: flex-end;
    margin-bottom: 5rem;
    font-family: "Lato", sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
  .limited-time-offer {
    background-color: #dbd1ff;
    padding: 16px;
    margin-top: -25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    color: #245938;
    font-family: "Lato", sans-serif;
    font-size: 16px;
  }
  .additional-features-description-container {
    display: flex;
    flex-direction: column;
  }
  
  .additional-features-description-container ul {
    list-style: none;
  }
  
  .additional-features-description-container li {
    color: #245938;
    font-family: Lato;
    font-size: 15px;
  }
  .additional-features-description-container li::before {
    text-indent: -0.7em;
    content: "* ";
    color: #245938;
  }
  .slick-list{
    position: relative;
    display: block;
    overflow: visible !important;
    margin: 0;
    padding: 0;
  }
}
