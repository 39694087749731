.catalyst-layout-width {
	width: 75% !important;
}


.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 20px; /* Adjust gap between items as needed */
}

.item {
  display: flex;
  /*align-items: center;*/
  justify-content: center;
}

.item img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 16px;
  max-height: 618px;
}

.salmon_container {
	border-radius: var(--spacing-20);
	display: flex;
	padding: var(--spacing-80) !important;
	flex-direction: column;
	gap: 36px;
}

.mask {
	height: 195px;
	width: 82vw;
    mask-image: url("../../assets/mobile_img_block.png");
    mask-position: 0% 0%;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
}


@media (max-width: 1025px) {
	.grid-container {
  		display: grid;
  		grid-template-columns: 1fr; /* Two equal columns */
  		gap: 20px; /* Adjust gap between items as needed */
	}

	.catalyst-layout-width {
		width: 80% !important;
	}

	.salmon_container {
		gap: var(--spacing-20);
		
	}
}

@media (max-width: 680px) {

	.catalyst-layout-width {
		width: 100% !important;
	}

	.grid-container {
  		display: grid;
  		grid-template-columns: 1fr; /* Two equal columns */
  		gap: 20px; /* Adjust gap between items as needed */
	}

	.catalyst_item_container {
		position: relative;
		height: auto;
		padding: 0rem;
		overflow: hidden;
		border-radius: 0 0 16px 16px;
	}

	.inner_container {
		height: 100px;
		overflow: hidden;
	}

	.catalyst_grid_item_img {
		width: 220px;
    	height: 220px;
    	object-fit: cover;
	}

	.catalyst_Home_Mobile_view {
		width: 100% !important;
		max-width: 100% !important;
		height: 350px !important;
		object-fit: contain;
		border-radius: 15px 10px 0px 0px !important;
	}

	.catalyst_details {
		background-color: #B67B22;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		position: relative;
		margin-top: 0px;
		border-radius: 0 0 16px 16px;
	}

	.salmon_container {
		display: flex;
		flex-direction: column;
		border-radius: var(--spacing-20);
		padding: 10% 24px 10% 24px !important;
		gap: var(--spacing-40);
	}

}