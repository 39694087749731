.maintenance_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 5rem;
}

.sub-heading {
	color: #B67B22;
	font-size: 20px;
}

.maintenance_img {
	width: 100%;
}

@media (max-width: 640px) {

  .maintenance_container > h1, .maintenance_container div {
  	text-align: center;
  }

  .maintenance_container {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-around;
	align-items: center;
	padding: 3rem;
  }
}
